import style from './Pagination.module.scss'


const Pagination = ({ tokensPerPage, totalTokens, paginate, setSelected, selected, pagetype }) => {
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(totalTokens / tokensPerPage); i++) {
        pageNumbers.push(i)
    }

    const handleChange = (event) => {
        setSelected(Number(event.target.value))
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }


    return (
        <div className={style.pagination}>
            {
                pageNumbers.map((number) => {
                    let currentId = pagetype + '_radio_' + number
                    return <div className={style.pageNumberButton} key={number}>
                        <input id={currentId} type="radio" name='radio' onChange={handleChange} value={number}
                            checked={selected === number} onClick={() => { paginate(number)}} />
                        <label htmlFor={currentId}>{number}</label>
                    </div>
                })
            }
        </div>
    )
}

export default Pagination;