import style from "./PortfolioTokenSwitcher.module.scss"

const PortfolioTokenSwitcher = ({selectedToken, setSelectedToken}) => {

    const handleChange = (event) => {
        setSelectedToken(event.target.value)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return (
        <div className={style.formRadioGroup} >
            <div className={style.formRadioGroupItem}>
                <input id="radio-1" type="radio" name="switcher" value="token"
                       checked={selectedToken === 'token'}
                       onChange={handleChange}/>
                <label htmlFor="radio-1">Tokens</label>
            </div>
            <div className={style.formRadioGroupItem}>
                <input id="radio-2" type="radio" name="switcher" value="NFT"
                       checked={selectedToken === 'NFT'}
                       onChange={handleChange}/>
                <label htmlFor="radio-2">NFT's</label>
            </div>
        </div>
    );
 }

 export default PortfolioTokenSwitcher;