import React from 'react';
import style from './ExchangesBlock.module.scss'
import ExchangeHeader from "./ExchangeHeader/ExchangeHeader";
import Exchange from "./Exchange/Exchange";


const ExchangesBlock = ({exchanges}) => {
    return (
        <>
            <ExchangeHeader/>
            <div className={style.exchangesInformation}>
                {exchanges.exchanges.map((data, index) =>
                    <Exchange data = {data} number = {index + 1} key = {index + 1}/>
                )}
            </div>
        </>
    );
};

export default ExchangesBlock;