import axios from "axios";
import {getAllTokensAction, getOneTokenAction} from "../store/tokensReducer";

export const fetchTokens = () => {
    return function (dispatch) {
        axios.get('https://ctokens.io/api/v1/tokens/')
            .then(data => {
                data.data.tokens.sort((a, b) => b.cap_ada - a.cap_ada)
                data.data.tokens.forEach((item, index) => item.index = index + 1)
                dispatch(getAllTokensAction(data.data))
            })
    }
}

export const fetchToken = (tokenId) => {
    return function (dispatch) {
        axios.get('https://ctokens.io/api/v1/tokens/')
            .then(data => dispatch(getOneTokenAction(data.data.tokens.find(item => `${item.policy_id}.${item.asset_name}` === tokenId))))
    }
}
