import React, { useEffect, useState } from 'react';
import style from './TokensHeader.module.scss'
import { sortTokens } from "../../../store/tokensReducer";
import { useDispatch } from "react-redux";
import arrowDown from '../../../assets/images/arrowDownForSort.png'
import arrowUp from '../../../assets/images/arrowUpForSort.png'
import TokensHeaderMobile from "./TokensHeaderMobile/TokensHeaderMobile";

const TokenHeader = ({ isSorter, setSorter, tokens, setSelected, setCurrentPage }) => {
    const dispatch = useDispatch()

    const [isLoadData, setLoadData] = useState(false)


    const sort = (sortName) => {
        let tokensWithSort = tokens.viewTokens
        switch (sortName) {
            case 'byName': {
                if (isSorter.isSorterArrow === 'arrowDown' && isSorter.sorter === sortName) {
                    setSorter({ sorter: sortName, isSorterArrow: 'arrowUp' })
                    tokensWithSort = tokensWithSort.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()))
                } else {
                    setSorter({ sorter: sortName, isSorterArrow: 'arrowDown' })
                    tokensWithSort = tokensWithSort.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                }
                dispatch(sortTokens({ tokens: tokensWithSort }))
                break
            }
            case 'byPrice': {
                if (isSorter.isSorterArrow === 'arrowDown' && isSorter.sorter === sortName) {
                    setSorter({ sorter: sortName, isSorterArrow: 'arrowUp' })
                    tokensWithSort = tokensWithSort.sort((a, b) => a.price_ada - b.price_ada)
                } else {
                    setSorter({ sorter: sortName, isSorterArrow: 'arrowDown' })
                    tokensWithSort = tokensWithSort.sort((a, b) => b.price_ada - a.price_ada)
                }
                dispatch(sortTokens({ tokens: tokensWithSort }))
                break
            }
            case 'byLiquidity': {
                if (isSorter.isSorterArrow === 'arrowDown' && isSorter.sorter === sortName) {
                    setSorter({ sorter: sortName, isSorterArrow: 'arrowUp' })
                    tokensWithSort = tokensWithSort.sort((a, b) => a.locked_liquidity_ada - b.locked_liquidity_ada)
                } else {
                    setSorter({ sorter: sortName, isSorterArrow: 'arrowDown' })
                    tokensWithSort = tokensWithSort.sort((a, b) => b.locked_liquidity_ada - a.locked_liquidity_ada)
                }
                dispatch(sortTokens({ tokens: tokensWithSort }))
                break
            }
            default: {
                break
            }
        }
    }

    useEffect(() => {
        if (tokens.tokens !== undefined) {
            setLoadData(true)
        }
    }, [tokens.tokens])

    useEffect(() => {
        let tokensWithSort = tokens.viewTokens
        setSorter({ sorter: 'byLiquidity', isSorterArrow: 'arrowDown' })
        tokensWithSort = tokensWithSort.sort((a, b) => b.locked_liquidity_ada - a.locked_liquidity_ada)
        dispatch(sortTokens({ tokens: tokensWithSort }))
    }, [dispatch, isLoadData, setSorter, tokens.tokens, tokens.viewTokens])

    return (
        <>
            <div className={style.tokensHeader}>
                <div className={style.numberToken}>
                    <span>NO</span>
                </div>
                <div className={style.nameTokenHeader} onClick={() => {
                    setCurrentPage(1)
                    setSelected(1)
                    sort('byName')
                }}>
                    <span>COIN</span>
                    {isSorter.sorter === 'byName' &&
                        <>
                            {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown' />}
                            {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp' />}
                        </>
                    }
                </div>
                <div className={style.priceTokenHeader} onClick={() => {
                    setCurrentPage(1)
                    setSelected(1)
                    sort('byPrice')
                }}>
                    <span>PRICE (ADA & USD)</span>
                    {isSorter.sorter === 'byPrice' &&
                        <>
                            {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown' />}
                            {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp' />}
                        </>
                    }
                </div>
                <div className={style.capPriceTokenHeader} onClick={() => {
                    setCurrentPage(1)
                    setSelected(1)
                    sort('byLiquidity')
                }}>
                    <span>LIQUIDITY</span>
                    {isSorter.sorter === 'byLiquidity' &&
                        <>
                            {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown' />}
                            {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp' />}
                        </>
                    }
                </div>
                <div className={style.linksHeader}>
                    <span>LINKS</span>
                </div>
            </div>
            <TokensHeaderMobile sort={sort} isSorter={isSorter} setSorter={setSorter} tokens={tokens} />
        </>

    );
};

export default TokenHeader;
