import React, { useState } from 'react'
import style from './PortfolioSearch.module.scss'
import refreshInputSearch from '../../assets/images/refreshSearch.png'

const PortfolioSearch = ({ isInformationWallet, setSorter, setDisplayedTokens, setCurrentPortfolioPage, setSelectedPortfolioPage }) => {
    const deletedTokens = ['IIC', 'madWBTC', 'madWETH']
    const [isFindInput, setFindInput] = useState('')
    const [isActiveInputPlaceholder, setActiveInputPlaceholder] = useState('Search for one of policyID or tokenName')

    const findTokens = (text) => {
        const tokensList = isInformationWallet.tokensWallet.filter(item => !deletedTokens.includes(item.name))
        const foundTokens = tokensList.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase()))
        const foundTokensNFT = isInformationWallet.tokensWalletNFT.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase()))
        setDisplayedTokens({ tokens: foundTokens, tokensNFT: foundTokensNFT })
        setSorter({ sorter: '', isSorterArrow: '' })
    }

    return (
        <>
            <div className={style.portfolioSearchSorterBlock}>
                <input value={isFindInput}
                    placeholder={isActiveInputPlaceholder}
                    onClick={() => setActiveInputPlaceholder('')}
                    onBlur={() => setActiveInputPlaceholder('Search for one of policyID or tokenName')}
                    onChange={(e) => {
                        setFindInput(e.target.value)
                        findTokens(e.target.value)
                        setSelectedPortfolioPage(1)
                        setCurrentPortfolioPage(1)
                    }}
                />
                <img src={refreshInputSearch} alt="" onClick={() => {
                    findTokens('')
                    setFindInput('')
                }} />
                {isActiveInputPlaceholder === '' && <span className={style.placeholderText}>Search for one of policyID or tokenName</span>}
            </div>
        </>
    )
}

export default PortfolioSearch;
