import React from 'react';
import style from './ExchangesPage.module.scss'
import {useSelector} from "react-redux";
import ExchangesBlock from "../../components/ExchangesBlock/ExchangesBlock";

const ExchangesPage = () => {

    const exchanges = useSelector(state => state.exchanges)


    return (
        <div className={style.exchangesBlock}>
            <div className={style.exchangesInformationBlock}>
                <ExchangesBlock exchanges={exchanges}/>
            </div>
        </div>
    );
};

export default ExchangesPage;