import React from 'react';
import style from "./TokenWallet.module.scss";
import {useNavigate} from "react-router-dom";
import {TOKEN_PAGE} from "../../../utils/const";
import notFoundTokenImage from '../../../assets/images/notFoundTokenImage.png'
import {formatNumber} from "../../../utils/formatNumber";
import {numberWithSpaces} from "../../../utils/numberWithSpaces";

const TokenWallet = (props) => {
    console.log(props)
    const history = useNavigate()
    const image = `https://ctokens.io/api/v1/tokens/images/${props.data.policy_id}.${props.data.asset_id}.png`

    const openDetailInformation = () => {
        if (props.data.fullPrice_ada) {
            history(`${TOKEN_PAGE}/${props.data.policy_id}.${props.data.name}`)
        }
    }

    return (
        <div className={style.tokens}>
            <div className={style.token} onClick={openDetailInformation}>
                <div className={style.numberToken}>
                    <span>{props.number}</span>
                </div>
                <div className={style.nameTokenBlock}>
                    <div className={style.imageToken}>
                        <img src={image} alt="" onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = notFoundTokenImage
                        }}/>
                    </div>
                    <div className={style.nameToken}>
                        <span>{props.data.name} {props.data.amount === 1 && <span style={{color: 'orange'}}>(NFT)</span>}</span>
                    </div>
                </div>
                <div className={style.priceToken}>
                    {props.data.fullPrice_ada === 0
                        ?
                        <span>Not supported</span>
                        :
                        <>
                            <span>₳ {formatNumber(props.data.price_ada.noExponents())}</span>
                            <span style={{color: 'orange'}}>$ {formatNumber(props.data.price_usd.noExponents())}</span>
                        </>

                    }

                </div>
                <div className={style.amount}>
                    <span>{numberWithSpaces(formatNumber(props.data.amount + ''))}</span>
                </div>
                <div className={style.fullPrice}>
                    {props.data.fullPrice_ada === 0
                        ?
                        <span>Not supported</span>
                        :
                        <>
                            <span>₳ {formatNumber(props.data.fullPrice_ada.noExponents())}</span>
                            <span style={{color: 'orange'}}>$ {formatNumber(props.data.fullPrice_usd.noExponents())}</span>
                        </>
                    }

                </div>
            </div>
        </div>
    );
};

export default TokenWallet;
