import React from 'react';
import style from './AboutPage.module.scss'
import blackRocketLogo from '../../assets/images/BlackRocketLogo.png'

const AboutPage = () => {
    return (
        <div className={style.aboutPage}>
            <div className={style.logoAbout}>
                <a href='https://blackrocket.space' rel='noreferrer' target='_blank'>
                    <img src={blackRocketLogo} alt=""/>
                </a>
            </div>
            <div className={style.textAbout}>
                <a href='https://blackrocket.space' rel='noreferrer' target='_blank' style={{textDecoration: 'none'}}>
                    This website is developed and supported by <a href="https://blackrocket.space" rel='noreferrer' target='_blank' style={{textDecoration: 'underline'}}>Black Rocket</a> Initiative, delegate your ADA wallet to our node <a
                    href='https://cardanoscan.io/pool/59b59f232e80f18ce64e0f74560effbf49a3e95ddf6d079681db8b23' rel= 'noreferrer' target= '_blank'>[BLACK]</a> to support this project.
                </a>
            </div>
        </div>
    );
};

export default AboutPage;
