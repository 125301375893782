import React from 'react';
import style from './TokensWalletHeader.module.scss'
import arrowDown from '../../../assets/images/arrowDownForSort.png'
import arrowUp from '../../../assets/images/arrowUpForSort.png'

const TokensWalletHeader = ({
                                isSorter,
                                setSorter,
                                displayedTokens,
                                setDisplayedTokens,
                                setSelectedPortfolioPage,
                                setCurrentPortfolioPage
                            }) => {

    const sort = (sortName) => {
        let tokensWithSort = displayedTokens.tokens
        let NftTokensWithSort = displayedTokens.tokensNFT
        switch (sortName) {
            case 'byName': {
                if (isSorter.isSorterArrow === 'arrowDown' && isSorter.sorter === sortName) {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowUp'})
                    tokensWithSort = tokensWithSort.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()))
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()))
                } else {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowDown'})
                    tokensWithSort = tokensWithSort.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                }
                break
            }
            case 'byPrice': {
                if (isSorter.isSorterArrow === 'arrowDown' && isSorter.sorter === sortName) {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowUp'})
                    tokensWithSort = tokensWithSort.sort((a, b) => a.price_ada - b.price_ada)
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => a.price_ada - b.price_ada)
                } else {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowDown'})
                    tokensWithSort = tokensWithSort.sort((a, b) => b.price_ada - a.price_ada)
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => b.price_ada - a.price_ada)
                }
                break
            }
            case 'byAmount': {
                if (isSorter.isSorterArrow === 'arrowDown' && isSorter.sorter === sortName) {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowUp'})
                    tokensWithSort = tokensWithSort.sort((a, b) => a.amount - b.amount)
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => a.amount - b.amount)
                } else {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowDown'})
                    tokensWithSort = tokensWithSort.sort((a, b) => b.amount - a.amount)
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => b.amount - a.amount)
                }
                break
            }
            case 'byFullPrice': {
                if (isSorter.isSorterArrow === 'arrowDown' && isSorter.sorter === sortName) {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowUp'})
                    tokensWithSort = tokensWithSort.sort((a, b) => a.fullPrice_ada - b.fullPrice_ada)
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => a.fullPrice_ada - b.fullPrice_ada)
                } else {
                    setSorter({sorter: sortName, isSorterArrow: 'arrowDown'})
                    tokensWithSort = tokensWithSort.sort((a, b) => b.fullPrice_ada - a.fullPrice_ada)
                    NftTokensWithSort = NftTokensWithSort.sort((a, b) => b.fullPrice_ada - a.fullPrice_ada)
                }
                break
            }
            default: {
                break
            }
        }
        setDisplayedTokens({tokens: tokensWithSort, tokensNFT: NftTokensWithSort})
    }
    return (
        <div className={style.tokensHeader}>
            <div className={style.numberToken}>
                <span>NO</span>
            </div>
            <div className={style.nameTokenHeader} onClick={() => {
                setSelectedPortfolioPage(1)
                setCurrentPortfolioPage(1)
                sort('byName')}}>
                <span>COIN</span>
                {
                    isSorter.sorter === 'byName' &&
                    <>
                        {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown'/>}
                        {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp'/>}
                    </>
                }
            </div>
            <div className={style.priceTokenHeader} onClick={() => {
                setSelectedPortfolioPage(1)
                setCurrentPortfolioPage(1)
                sort('byPrice')}}>
                <span>PRICE (ADA & USD)</span>
                {
                    isSorter.sorter === 'byPrice' &&
                    <>
                        {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown'/>}
                        {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp'/>}
                    </>
                }
            </div>
            <div className={style.capPriceTokenHeader} onClick={() => {
                setSelectedPortfolioPage(1)
                setCurrentPortfolioPage(1)
                sort('byAmount')}}>
                <span>AMOUNT</span>
                {
                    isSorter.sorter === 'byAmount' &&
                    <>
                        {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown'/>}
                        {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp'/>}
                    </>
                }
            </div>
            <div className={style.fullPriceTokenHeader} onClick={() => {
                setSelectedPortfolioPage(1)
                setCurrentPortfolioPage(1)
                sort('byFullPrice')}}>
                <span>FULL PRICE</span>
                {
                    isSorter.sorter === 'byFullPrice' &&
                    <>
                        {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown'/>}
                        {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp'/>}
                    </>
                }
            </div>
        </div>
    );
};

export default TokensWalletHeader;