import axios from "axios";
import {getAllExchangesAction, getOneExchangeAction} from "../store/exchangesReducer";

export const fetchExchanges = () => {
    return function (dispatch) {
        axios.get('https://ctokens.io/api/v1/exchanges/')
            .then(data => {
                dispatch(getAllExchangesAction(data.data.exchanges))
            })
    }
}

export const fetchExchange = (name) => {
    return function (dispatch) {
        axios.get('https://ctokens.io/api/v1/exchanges/')
            .then(data => dispatch(getOneExchangeAction(data.data.exchanges.find(item => item.name === name))))
    }
}
