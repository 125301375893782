import React from 'react';
import style from './NotFoundPage.module.scss'

const NotFoundPage = () => {
    return (
        <div>
            Страница не найдена
        </div>
    );
};

export default NotFoundPage;