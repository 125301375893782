import React, { useEffect, useState } from 'react';
import style from './TokensBlock.module.scss'
import TokensHeader from "./TokensHeader/TokenHeader";
import Token from "./Token/Token";
import Pagination from "../Pagination/Pagination";
import { useSelector } from "react-redux";



const TokensBlock = ({ isSorter, setSorter, tokens, search, selected, currentPage, setCurrentPage, setSelected }) => {
    const [tokensPerPage] = useState(50)
    const lastTokenIndex = currentPage * tokensPerPage
    const firstTokenIndex = lastTokenIndex - tokensPerPage
    const currentToken = tokens.viewTokens.slice(firstTokenIndex, lastTokenIndex)
    const paginate = pageNumber => setCurrentPage(pageNumber)
    return (
        <>
            {
                tokens.viewTokens.length <= 50
                    ?
                        <>
                            <TokensHeader isSorter={isSorter} setSorter={setSorter} tokens={tokens} setCurrentPage={setCurrentPage}
                                          setSelected={setSelected}/>
                            <div className={style.tokensBlock}>
                                {
                                    tokens.viewTokens.map((item, index) =>
                                        <Token data={item} number={ 1 + index} key={item.token_id} isSorter={isSorter} />
                                    )
                                }
                            </div>
                        </>
                    :
                        <>
                            <TokensHeader isSorter={isSorter} setSorter={setSorter} tokens={tokens} setCurrentPage={setCurrentPage}
                                          setSelected={setSelected}/>
                            <div className={style.tokensBlock}>
                                {
                                    currentToken.map((item, index) =>
                                        <Token data={item} number={firstTokenIndex + 1 + index} key={item.token_id} isSorter={isSorter}/>
                                    )
                                }
                            </div>
                            <Pagination tokensPerPage={tokensPerPage} totalTokens={tokens.viewTokens.length} paginate={paginate}
                                        search={search} selected={selected} setSelected={setSelected} pagetype='tokens' />
                        </>
            }
        </>

    );
};

export default TokensBlock;
