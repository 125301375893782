import React, { useEffect, useState } from 'react';
import style from './InformationTokenPage.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchToken } from "../../actions/tokens";
import { TOKENS_PAGE } from "../../utils/const";
import notFound from '../../assets/images/notFound.png'
import blockchainLogo from '../../assets/images/blockchainLogo.png'
import internetLogo from '../../assets/images/internetLogo.png'
import { getOneTokenAction } from "../../store/tokensReducer";
import { numberWithSpaces } from "../../utils/numberWithSpaces";
import Trend from "../../components/Trend/Trend";
import ExchangesBlock from "../../components/ExchangesBlock/ExchangesBlock";
import { formatNumber } from "../../utils/formatNumber";
import notFoundTokenImage from "../../assets/images/notFoundTokenImage.png";
import tokenMenu from "../../components/TokensBlock/TokenMenu/TokenMenu";

const InformationTokenPage = () => {
    const {tokenId} = useParams()
    const history = useNavigate()

    const {token, courseUSD} = useSelector(state => state.tokens)
    let exchanges = useSelector(state => state.exchanges)
    const dispatch = useDispatch()
    const image = `https://ctokens.io/api/v1/tokens/images/${token.policy_id}.${token.asset_id}.png`

    const [isExchanges, setExchanges] = useState({exchanges: []})

    useEffect(() => {
        dispatch(fetchToken(tokenId))
    }, [dispatch, tokenId])

    useEffect(() => {
        if (exchanges.exchanges !== undefined && token.exchanges !== undefined) {
            const exchangesCurrentToken = exchanges.exchanges.filter(exchange => token.exchanges.join('').includes(exchange.id))
            setExchanges({exchanges: exchangesCurrentToken})
        }
    }, [exchanges, token.exchanges])

    const showMarketCapWithSpaces = (market_cap) => {
        if (market_cap) {
            return numberWithSpaces(market_cap.toFixed(0))
        }
    }


    return (
        <div className={style.tokenInformationBlock}>
            {
                token === undefined
                    ?
                    <div className={style.missingDataBlock}>
                        <span>The token is missing</span>
                        <img src={notFound} alt=""/>
                        <button onClick={() => {
                            history(TOKENS_PAGE)
                            dispatch(getOneTokenAction({}))
                        }}>All tokens
                        </button>
                    </div>
                    :
                    <div className={style.tokenInformation}>
                        <div className={style.tokenDescriptionBlock}>
                            <div className={style.nameDescriptionBlock}>
                                <div className={style.nameToken}>
                                    <div>
                                        <img src={image} onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = notFoundTokenImage
                                        }} alt=""/>
                                    </div>
                                    <div className={style.fullNameBlock}>
                                        {token.trend !== 0 && <Trend trend={token.trend}/>}
                                    </div>
                                </div>
                                <div className={style.descriptionToken}>
                                    <p>{token.description}</p>
                                </div>
                            </div>
                            <div className={style.pricesTokenBlock}>
                                <div className={style.headerPrices}>
                                    <span>{token.name}</span>
                                </div>
                                <div className={style.pricesBlock}>
                                    <div className={style.prices}>
                                        <div className={style.priceBlock}>
                                            <div className={style.headerPrice}>
                                                <span> PRICE (USD)</span>
                                            </div>
                                            <div className={style.price}>
                                                <span
                                                    style={{color: 'orange'}}>$ {token.price_usd && formatNumber(token.price_usd.noExponents())}</span>
                                            </div>
                                        </div>
                                        <div className={style.priceBlock}>
                                            <div className={style.headerPrice}>
                                                <span> PRICE (ADA)</span>
                                            </div>
                                            <div className={style.price}>
                                                <span>₳ {token.price_ada && formatNumber(token.price_ada.noExponents())}</span>
                                            </div>
                                        </div>
                                        <div className={style.priceBlock}>
                                            <div className={style.headerPrice}>
                                                <span>LIQUIDITY</span>
                                            </div>
                                            <div className={style.price}>
                                                <span>₳ {showMarketCapWithSpaces(token.locked_liquidity_ada)}</span>
                                            </div>
                                            <div className={style.price}>
                                                <span
                                                    style={{color: 'orange'}}>$ {showMarketCapWithSpaces(Math.trunc(token.locked_liquidity_ada) * courseUSD)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.links}>
                                        <div className={style.linkBlock}>
                                            <div className={style.linkImage}>
                                                <img src={blockchainLogo} alt="" className='internetLogo'/>
                                            </div>
                                            <div className={style.linkText}>
                                                <a href={`https://cardanoscan.io/token/${token.policy_id}.${token.asset_name}`} target="_blank" rel="noreferrer">
                                                    Blockchain</a>
                                            </div>
                                        </div>
                                        {token.token_link &&
                                            <div className={style.linkBlock}>
                                                <div className={style.linkImage}>
                                                    <img src={internetLogo} alt=""/>
                                                </div>
                                                <div className={style.linkText}>
                                                    <a href={token.token_link} target="_blank"
                                                       rel="noreferrer"> Token homepage</a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.tokenDescriptionBlockMobile}>
                            <div className={style.tokenNameMobile}>
                                <img src={image} alt=""/>
                                <span>{token.name}</span>
                                {token.trend !== 0 && <Trend trend={token.trend}/>}
                            </div>
                            <div className={style.tokenPricesMobile}>
                                <div className={style.tokenPriceMobile}>
                                    <div className={style.namePriceMobile}>
                                        <span>PRICE (USD)</span>
                                    </div>
                                    <div className={style.priceMobile}>
                                        <span>{token.price_usd && <span
                                            style={{color: 'orange'}}>{formatNumber(token.price_usd.noExponents())} $ </span>}</span>
                                    </div>
                                </div>
                                <div className={style.tokenPriceMobile}>
                                    <div className={style.namePriceMobile}>
                                        <span>PRICE (ADA)</span>
                                    </div>
                                    <div className={style.priceMobile}>
                                        <span>₳ {token.price_ada && formatNumber(token.price_ada.noExponents())}</span>
                                    </div>
                                </div>
                                <div className={style.tokenPriceMobile}>
                                    <div className={style.namePriceMobile}>
                                        <span>LIQUIDITY</span>
                                    </div>
                                    <div className={style.priceMobile}>
                                        <span>₳ {showMarketCapWithSpaces(token.locked_liquidity_ada)}</span>
                                    </div>
                                    <div className={style.priceMobile}>
                                        <span
                                            style={{color: 'orange'}}>$ {showMarketCapWithSpaces(Math.trunc(token.locked_liquidity_ada) * courseUSD)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={style.tokenLinksMobile}>
                                <div className={style.mobileLink}>
                                    <img src={blockchainLogo} alt=""/>
                                    <a href={`https://cardanoscan.io/token/${token.policy_id}.${token.asset_name}`} target="_blank" rel="noreferrer">Blockchain</a>
                                </div>
                                {token.token_link &&
                                    <div className={style.mobileLink}>
                                        <img src={internetLogo} alt=""/>
                                        <a href={token.token_link} target="_blank" rel="noreferrer">Token homepage</a>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={style.tokenExchanges}>
                            <ExchangesBlock exchanges={isExchanges}/>
                        </div>
                    </div>
            }

        </div>
    );
};

export default InformationTokenPage;
