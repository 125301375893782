import React, { useEffect, useState } from 'react';
import style from './PortfolioPage.module.scss'
import cbor from "cbor-js";
import blackRocketLogo from '../../assets/images/BlackRocketLogo.png'
import ModalSelectWallet from "./ModalSelectWallet/ModalSelectWallet";
import TokensWalletHeader from "./TokensWalletHeader/TokensWalletHeader";
import TokenWallet from "./TokenWallet/TokenWallet";
import { useSelector } from "react-redux";
import { formatNumber } from "../../utils/formatNumber";
import Loading from "../../components/Loading/Loading";
import PortfolioSearch from '../../components/PortfolioSearch/PortfolioSearch';
import Pagination from "../../components/Pagination/Pagination";
import PortfolioTokenSwitcher from "../../components/PortfolioTokenSwitcher/PortfolioTokenSwitcher";

const PortfolioPage = () => {
    const deletedTokens = ['IIC', 'madWBTC', 'madWETH']
    const [selectedToken, setSelectedToken] = useState('token')
    const tokens = useSelector(state => state.tokens.tokens)
    const courseUsd = useSelector(state => state.tokens.courseUSD)

    const [isSorter, setSorter] = useState({sorter: '', isSorterArrow: ''})
    const [isVisibleModal, setVisibleModal] = useState(false)
    const [isActiveWallet, setActiveWallet] = useState(false)
    const [isLoadingPortfolio, setLoadingPortfolio] = useState(false)
    const [isActiveAddress, setActiveAddress] = useState('')
    const [count, setCount] = useState(0);
    const [isDataWallet, setDataWallet] = useState({icon: '', name: ''})
    const [isInformationWallet, setInformationWallet] = useState({
        balance: 0,
        tokensWallet: [],
        tokensBalance: 0,
        tokensWalletNFT: []
    })
    const [displayedTokens, setDisplayedTokens] = useState({tokens: [], tokensNFT: []})

    const onOpenListWallets = () => setVisibleModal(true)
    const onCloseListWallets = () => setVisibleModal(false)
    const onActiveWallet = () => setActiveWallet(true)

    const disconnectWallet = () => {
        localStorage.clear()
        setActiveWallet(false)
    }

    if (window.sessionStorage.getItem('currentPortfolioPage') === null) {
        window.sessionStorage.setItem('currentPortfolioPage', 1)
        window.sessionStorage.setItem('selectedPortfolioPage', 1)

        window.sessionStorage.setItem('currentPortfolioNFTPage', 1)
        window.sessionStorage.setItem('selectedPortfolioNFTPage', 1)
    }

    const [currentPortfolioPage, setCurrentPortfolioPage] = useState(Number(window.sessionStorage.getItem('currentPortfolioPage')))
    const [selectedPortfolioPage, setSelectedPortfolioPage] = useState(Number(window.sessionStorage.getItem('selectedPortfolioPage')))

    const [currentPortfolioNFTPage, setCurrentPortfolioNFTPage] = useState(Number(window.sessionStorage.getItem('currentPortfolioNFTPage')))
    const [selectedPortfolioNFTPage, setSelectedPortfolioNFTPage] = useState(Number(window.sessionStorage.getItem('selectedPortfolioNFTPage')))

    const [tokensPerPage] = useState(50)
    const lastTokenIndex = currentPortfolioPage * tokensPerPage
    const firstTokenIndex = lastTokenIndex - tokensPerPage
    const currentToken = displayedTokens.tokens.slice(firstTokenIndex, lastTokenIndex)
    const paginate = pageNumber => setCurrentPortfolioPage(pageNumber)

    const lastNFTTokenIndex = currentPortfolioNFTPage * tokensPerPage
    const firstNFTTokenIndex = lastNFTTokenIndex - tokensPerPage
    const currentNFTToken = displayedTokens.tokensNFT.slice(firstNFTTokenIndex, lastNFTTokenIndex)

    useEffect(() => {
        setCurrentPortfolioPage(JSON.parse(window.sessionStorage.getItem('currentPortfolioPage')))
        setCurrentPortfolioNFTPage(JSON.parse(window.sessionStorage.getItem('currentPortfolioNFTPage')))
        setSelectedPortfolioPage(JSON.parse(window.sessionStorage.getItem('selectedPortfolioPage')))
        setSelectedPortfolioNFTPage(JSON.parse(window.sessionStorage.getItem('selectedPortfolioNFTPage')))
    }, [])

    useEffect(() => {
        window.sessionStorage.setItem('currentPortfolioPage', currentPortfolioPage)
        window.sessionStorage.setItem('currentPortfolioNFTPage', currentPortfolioNFTPage)
        window.sessionStorage.setItem('selectedPortfolioPage', selectedPortfolioPage)
        window.sessionStorage.setItem('selectedPortfolioNFTPage', selectedPortfolioNFTPage)
    }, [setSelectedPortfolioPage, setCurrentPortfolioPage, currentPortfolioPage, selectedPortfolioPage])

    useEffect(() => {
        if (localStorage.length !== 0) {
            const icon = localStorage.getItem('icon')
            const name = localStorage.getItem('name')
            setDataWallet({icon: icon, name: name})
            setActiveWallet(true)
        }
    }, [isActiveWallet])

    useEffect(() => {
        const getChangeAccount = () => {
            setTimeout(async () => {
                if (localStorage.name === 'Nami') {
                    await window.cardano.nami.enable().then(data => data.getChangeAddress().then((data) => setActiveAddress(data)))
                    setCount(count + 1);
                } else if (localStorage.name === 'Typhon Wallet') {
                    await window.cardano.typhoncip30.enable().then(data => data.getChangeAddress().then((data) => setActiveAddress(data)))
                    setCount(count + 1);
                } else if (localStorage.name === 'Flint Wallet') {
                    await window.cardano.flint.enable().then(data => data.getChangeAddress().then((data) => setActiveAddress(data)))
                    setCount(count + 1);
                } else if (localStorage.name === 'GeroWallet') {
                    await window.cardano.gerowallet.enable().then(data => data.getChangeAddress().then((data) => setActiveAddress(data)))
                    setCount(count + 1);
                } else if (localStorage.name === 'eternl') {
                    await window.cardano.eternl.enable().then(data => data.getChangeAddress().then((data) => setActiveAddress(data)))
                    setCount(count + 1);
                }
            }, 2000)
        }

        getChangeAccount()
    }, [])

    useEffect(() => {
        const checkWallet = async () => {
            if (isActiveWallet === true && tokens.length !== 0) {
                if (localStorage.name === 'Nami') {
                    setLoadingPortfolio(true)
                    await window.cardano.nami.enable().then(async (data) => {
                        const hex = await data.getBalance()
                        await setInformationWallet(createDataBalance(hex, tokens))
                        setLoadingPortfolio(false)
                    })
                } else if (localStorage.name === 'Typhon Wallet') {
                    setLoadingPortfolio(true)
                    await window.cardano.typhoncip30.enable().then(async (data) => {
                        const hex = await data.getBalance()
                        await setInformationWallet(createDataBalance(hex, tokens))
                        setLoadingPortfolio(false)
                    })
                } else if (localStorage.name === 'Flint Wallet') {
                    setLoadingPortfolio(true)
                    await window.cardano.flint.enable().then(async (data) => {
                        const hex = await data.getBalance()
                        await setInformationWallet(createDataBalance(hex, tokens))
                        setLoadingPortfolio(false)
                    })
                } else if (localStorage.name === 'GeroWallet') {
                    setLoadingPortfolio(true)
                    await window.cardano.gerowallet.enable().then(async (data) => {
                        const hex = await data.getBalance()
                        await setInformationWallet(createDataBalance(hex, tokens))
                        setLoadingPortfolio(false)
                    })
                } else if (localStorage.name === 'eternl') {
                    setLoadingPortfolio(true)
                    await window.cardano.eternl.enable().then(async (data) => {
                        const hex = await data.getBalance()
                        await setInformationWallet(createDataBalance(hex, tokens))
                        setLoadingPortfolio(false)
                    })
                }
            }
        }

        checkWallet()
    }, [tokens, isActiveWallet, isActiveAddress])

    useEffect(() => {
        setDisplayedTokens({
            tokens: isInformationWallet.tokensWallet.filter(item => !deletedTokens.includes(item.name)),
            tokensNFT: isInformationWallet.tokensWalletNFT
        })
    }, [isInformationWallet])

    const delegate = async () => {
        try {
            await window.cardano.typhon.isEnabled().then(async ({data}) => {
                if (data === true) {
                    await window.cardano.typhon.delegationTransaction({poolId: 'pool1tx6e7gewsrcceejwpa69vrhlhay6862amaks095pmw9jxulwhvx'})
                } else {
                    await window.cardano.typhon.enable().then(async () => {
                        await window.cardano.typhon.delegationTransaction({poolId: 'pool1tx6e7gewsrcceejwpa69vrhlhay6862amaks095pmw9jxulwhvx'})
                    })
                }
            }).catch(console.log)
        } catch (e) {
            window.open('https://typhonwallet.io/#/', '_blank')
        }

    }

    if (isLoadingPortfolio) {
        return (
            <Loading/>
        )
    }
    return (
        <>
            <ModalSelectWallet visible={isVisibleModal} onClose={onCloseListWallets} setActiveWallet={onActiveWallet}/>
            {
                isActiveWallet
                    ?
                    <div className={style.portfolioInformationBlock}>
                        <div className={style.headerBalance}>
                            <div className={style.walletInformationBlock}>
                                <div className={style.nameWallet}>
                                    <img src={isDataWallet.icon} alt=""/>
                                    <span>{isDataWallet.name}</span>
                                </div>
                                {localStorage.name === 'Typhon Wallet' &&
                                    <button className={style.delegateBtn} onClick={delegate}>
                                        <img src={blackRocketLogo} alt=""/>
                                        <span>Delegate to Black Rocket</span>
                                    </button>
                                }
                                <div className={style.portfolioHeader}>
                                    <div className={style.portfolioDisconnect}>
                                        <button onClick={disconnectWallet}>Disconnect</button>
                                    </div>
                                </div>
                            </div>
                            <div className={style.balanceInformationBlock}>
                                <span>Balance: <span>{isNaN(isInformationWallet.balance) ? 0 : formatNumber(isInformationWallet.balance.noExponents())} ₳ </span>/ <span
                                    style={{color: 'orange'}}>{isNaN(isInformationWallet.balance) ? 0 : (isInformationWallet.balance * courseUsd).toFixed(2)} $</span></span>
                                <span>Tokens({isInformationWallet.tokensWallet.length}): <span>{formatNumber(isInformationWallet.tokensBalance)} ₳ </span>/ <span
                                    style={{color: 'orange'}}>{+(isInformationWallet.tokensBalance * courseUsd).toFixed(2)} $</span></span>
                                <span>Total: <span>{isNaN(isInformationWallet.balance) ? 0 : formatNumber(isInformationWallet.tokensBalance + isInformationWallet.balance)} ₳ </span>/ <span
                                    style={{color: 'orange'}}>{isNaN(+(isInformationWallet.tokensBalance * courseUsd + isInformationWallet.balance * courseUsd).toFixed(2)) ? 0 : +(isInformationWallet.tokensBalance * courseUsd + isInformationWallet.balance * courseUsd).toFixed(2)} $</span></span>
                            </div>
                        </div>
                        <div className={style.tokensWalletBlock}>
                            <div className={style.sortTokensBlock}>
                                <PortfolioTokenSwitcher selectedToken={selectedToken} setSelectedToken={setSelectedToken}/>
                                <PortfolioSearch isInformationWallet={isInformationWallet}
                                                 setSorter={setSorter}
                                                 setDisplayedTokens={setDisplayedTokens}
                                                 setCurrentPortfolioPage={setCurrentPortfolioPage}
                                                 setSelectedPortfolioPage={setSelectedPortfolioPage}/>
                            </div>
                            {
                                selectedToken === 'token' ?
                                    <>
                                        {
                                            displayedTokens.tokens.length === 0 ?
                                                <>
                                                    <div className={style.notAvailableTokens}>
                                                        <span>Tokens are not available on the balance</span>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {
                                                        displayedTokens.tokens.length <= 50
                                                            ?
                                                            <>
                                                                <div className={style.tokensBlock}>
                                                                    <TokensWalletHeader
                                                                        displayedTokens={displayedTokens}
                                                                        isSorter={isSorter} setSorter={setSorter}
                                                                        setDisplayedTokens={setDisplayedTokens}
                                                                        setCurrentPortfolioPage={setCurrentPortfolioPage}
                                                                        setSelectedPortfolioPage={setSelectedPortfolioPage}/>
                                                                    <div className={style.tokensList}>
                                                                        {currentToken.map((item, index) =>
                                                                            <TokenWallet data={item}
                                                                                         number={firstTokenIndex + index + 1}
                                                                                         key={index}/>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className={style.tokensBlock}>
                                                                    <TokensWalletHeader
                                                                        displayedTokens={displayedTokens}
                                                                        isSorter={isSorter} setSorter={setSorter}
                                                                        setDisplayedTokens={setDisplayedTokens}
                                                                        setCurrentPortfolioPage={setCurrentPortfolioPage}
                                                                        setSelectedPortfolioPage={setSelectedPortfolioPage}/>

                                                                    {currentToken.map((item, index) =>
                                                                        <TokenWallet data={item}
                                                                                     number={firstTokenIndex + index + 1}
                                                                                     key={index}
                                                                        />
                                                                    )}
                                                                    <div className={style.paginationBlock}>
                                                                        <Pagination tokensPerPage={tokensPerPage}
                                                                                    paginate={paginate}
                                                                                    selected={selectedPortfolioPage}
                                                                                    setSelected={setSelectedPortfolioPage}
                                                                                    totalTokens={displayedTokens.tokens.length}
                                                                                    pagetype='portfolio'/>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            currentNFTToken.length === 0 ?
                                                <>
                                                    <div className={style.notAvailableTokens}>
                                                        <span>NFT tokens are not available on the balance</span>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className={style.blockNft}>
                                                        <TokensWalletHeader displayedTokens={displayedTokens}
                                                                            isSorter={isSorter} setSorter={setSorter}
                                                                            setCurrentPortfolioPage={setCurrentPortfolioNFTPage}
                                                                            setSelectedPortfolioPage={setSelectedPortfolioNFTPage}/>
                                                        {currentNFTToken.map((item, index) =>
                                                            <TokenWallet data={item}
                                                                         number={firstNFTTokenIndex + index + 1}
                                                                         key={index}/>
                                                        )}
                                                        <div className={style.paginationBlock}>
                                                            <Pagination tokensPerPage={tokensPerPage}
                                                                        paginate={paginate}
                                                                        selected={selectedPortfolioNFTPage}
                                                                        setSelected={setSelectedPortfolioNFTPage}
                                                                        totalTokens={displayedTokens.tokensNFT.length}
                                                                        pagetype='portfolioNft'/>
                                                        </div>
                                                    </div>
                                                </>
                                        }

                                    </>
                            }
                        </div>
                    </div>
                    :
                    <div className={style.walletPageBlock}>
                        <div className={style.connectWalletBlock}>
                            <button className={style.btnConnect} onClick={onOpenListWallets}>Connect wallet</button>
                        </div>
                    </div>
            }
        </>
    );
};

export default PortfolioPage;

const createDataWalletTokens = (hex) => {
    const fullInformationToken = {
        arrayTokensWallet: [],
        balance: 0
    }

    const typedArray = new Uint8Array(hex.match(/[\da-f]{2}/gi).map(function (h) {
        return parseInt(h, 16)
    }))

    const balance = cbor.decode(typedArray.buffer)

    const tokens = balance[1]


    const toHexString = (byteArray) => {
        return Array.from(byteArray, function (byte) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join('')
    }

    for (const policyId in tokens) {
        let dataToken = {
            policyId: toHexString(policyId.split(',')),
            assetName: toHexString(Object.keys(tokens[policyId])[0].split(',')),
            amount: Object.values(tokens[policyId])[0]
        }
        dataToken.assetId = dataToken.policyId + dataToken.assetName
        fullInformationToken.arrayTokensWallet.push(dataToken)
    }

    fullInformationToken.balance = balance[0] ? balance[0] / 1000000 : balance / 1000000

    return fullInformationToken
}

function hexToString(str1) {
    let hex = str1.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}

const createDataBalance = (hex, tokens) => {
    const allInformationAboutTokens = {balance: 0, tokensWallet: [], tokensBalance: 0, tokensWalletNFT: []}
    const tokensWallet = createDataWalletTokens(hex)
    allInformationAboutTokens.balance = tokensWallet.balance
    tokensWallet.arrayTokensWallet.map(item => {
        const tokenId = `${item.policyId}${item.assetName}`
        const tokenInformationAvailable = tokens.find(item => `${item.policy_id}${item.asset_id}` === tokenId)
        if (tokenInformationAvailable !== undefined) {
            const {name, price_ada, price_usd, policy_id, asset_id, decimals} = tokenInformationAvailable
            let amount = item.amount

            if (decimals > 0) {
                amount /= Math.pow(10, decimals)
            }
            const dataTokens = {
                name,
                price_ada,
                price_usd,
                policy_id,
                asset_id,
                amount,
                fullPrice_ada: price_ada * amount,
                fullPrice_usd: price_usd * amount
            }

            if (amount === 1) {
                return allInformationAboutTokens.tokensWalletNFT.push(dataTokens)
            } else {
                return allInformationAboutTokens.tokensWallet.push(dataTokens)
            }
        } else {
            const {assetName, amount, policyId} = item
            const price_ada = 0, price_usd = 0;
            const dataWallet = {
                name: hexToString(assetName),
                policy_id: policyId,
                amount,
                price_ada,
                price_usd,
                fullPrice_ada: price_ada * amount,
                fullPrice_usd: price_usd * amount
            }
            if (amount === 1) {
                return allInformationAboutTokens.tokensWalletNFT.push(dataWallet)
            } else {
                return allInformationAboutTokens.tokensWallet.push(dataWallet)
            }
        }
    })
    allInformationAboutTokens.tokensBalance = allInformationAboutTokens.tokensWallet.reduce((acc, prev) => {
        return acc + prev.fullPrice_ada
    }, 0)
    allInformationAboutTokens.tokensWallet.sort((a, b) => b.fullPrice_ada - a.fullPrice_ada)

    return allInformationAboutTokens
}
