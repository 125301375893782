import React, { useEffect, useState } from 'react';
import style from './TokensPage.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { sortTokens } from "../../store/tokensReducer";
import refreshInputSearch from '../../assets/images/refreshSearch.png'
import TokensBlock from "../../components/TokensBlock/TokensBlock";

const TokensPage = () => {
    const tokens = useSelector(state => state.tokens)
    const dispatch = useDispatch()
    const [isSorter, setSorter] = useState({ sorter: '', isSorterArrow: '' })
    const [isTokens, setTokens] = useState({ viewTokens: [] })
    const [isFindInput, setFindInput] = useState('')
    const [isActiveInputPlaceholder, setActiveInputPlaceholder] = useState('Search for one of policyID or tokenName')

    const [currentPage, setCurrentPage] = useState(1)
    const [selected, setSelected] = useState(1)

    useEffect(() => {
        setCurrentPage(+JSON.parse(window.sessionStorage.getItem('currentPage')) || 1)
        setSelected(+JSON.parse(window.sessionStorage.getItem('selected')) || 1)
    }, [])

    useEffect(() => {
        window.sessionStorage.setItem('currentPage', currentPage)
        window.sessionStorage.setItem('selected', selected)
    }, [currentPage, setCurrentPage, selected, setSelected])

    useEffect(() => {
        setTokens({ viewTokens: tokens.tokens})
    }, [tokens.tokens])

    const findTokens = (text) => {
        setSorter({ sorter: '', isSorterArrow: '' })
        const tokensList = tokens.tokens
        const foundTokens = tokensList.filter((item) =>
            item.policy_id.toLowerCase() === text ||
            item.name.toLowerCase().includes(text.toLowerCase())
        )
        setTokens({ viewTokens: foundTokens })
        dispatch(sortTokens({ tokens: foundTokens }))
    }

    return (
        <div className={style.tokenPageBlock}>
            <div className={style.tokenPageSorterBlock}>
                <input value={isFindInput}
                    placeholder={isActiveInputPlaceholder}
                    onClick={() => setActiveInputPlaceholder('')}
                    onBlur={() => setActiveInputPlaceholder('Search for one of policyID or tokenName')}
                    onChange={(e) => {
                        setFindInput(e.target.value)
                        findTokens(e.target.value)
                        setCurrentPage(1)
                        setSelected(1)
                    }}
                />
                <img src={refreshInputSearch} alt="" onClick={() => {
                    findTokens('')
                    setFindInput('')
                }} />
                {isActiveInputPlaceholder === '' && <span className={style.placeholderText}>Search for one of policyID or tokenName</span>}
            </div>
            <div className={style.tokensInformationBlock}>
                <TokensBlock tokens={isTokens} isSorter={isSorter} setSorter={setSorter} search={isFindInput}
                    selected={selected} setSelected={setSelected} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
};

export default TokensPage;
