import React from 'react';
import style from './Trend.module.scss'

const Trend = ({trend}) => {
    return (
        <div>
            {
                trend > 0
                &&
                <span style={{color:"green"}}>🠕 {trend.toFixed(2)}%</span>
            }
            {
                trend < 0
                &&
                <span style={{color:"red"}}>🠗 {trend.toFixed(2)}%</span>
            }

        </div>
    );
};

export default Trend;