import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import tokensReducer from "./tokensReducer";
import exchangesReducer from "./exchangesReducer";


const rootReducer = combineReducers({
    tokens: tokensReducer,
    exchanges: exchangesReducer
})

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
)