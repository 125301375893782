import React from 'react';
import style from './MobileNavigationModal.module.scss'
import activeLink from '../../assets/images/ActiveLink.svg'
import nonActiveLink from '../../assets/images/NonActiveLink.svg'
import {useLocation, useNavigate} from "react-router-dom";
import {ABOUT_PAGE, EXCHANGES_PAGE, TOKENS_PAGE} from "../../utils/const";

const MobileNavigationModal = ({visible, onClose}) => {

    const location = useLocation()

    const history = useNavigate()

    const setActiveImage = (route) => {
        if (location.pathname === route) {
            return activeLink
        } else {
            return nonActiveLink
        }
    }

    const changeLocation = (page) => {
        history(page)
        onClose()
    }


    if (!visible) return null
    return (
        <div className={style.modalBlock} onClick={onClose}>
            <div className={style.modalLinks} onClick={e => e.stopPropagation()}>
                <div className={style.modalLink} onClick={() => changeLocation(TOKENS_PAGE)}>
                    <div className={style.statusActiveLink}>
                        <img src={setActiveImage(TOKENS_PAGE)} alt=""/>
                    </div>
                    <div className={style.link}>
                        <span>Tokens</span>
                    </div>
                </div>
                <div className={style.modalLink} onClick={() => changeLocation(EXCHANGES_PAGE)}>
                    <div className={style.statusActiveLink}>
                        <img src={setActiveImage(EXCHANGES_PAGE)} alt=""/>
                    </div>
                    <div className={style.link}>
                        <span>Exchanges</span>
                    </div>
                </div>
                <div className={style.modalLink} onClick={() => changeLocation(ABOUT_PAGE)}>
                    <div className={style.statusActiveLink}>
                        <img src={setActiveImage(ABOUT_PAGE)} alt=""/>
                    </div>
                    <div className={style.link}>
                        <span>About</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileNavigationModal;