import React from 'react';
import style from './MobilePrivacyPolicy.module.scss'

const MobilePrivacyPolicy = () => {
    return (
        <div className={style.PrivacyPolicy}>
            <h1>Mobile App Privacy Policy</h1>
            <div className={style.textBlock}>
                <h2>Introduction</h2>
                <p>Our Cardano Tokens Portfolio app was designed with the fundamental principle that your private data should remain confidential and strictly under your control. Consequently, our app keeps your data entirely offline; all your data is stored exclusively on your device, whether it's a smartphone, tablet, or computer. There are no external servers or cloud storage involved. This Privacy Policy elucidates how the app manages information and emphasizes your role in safeguarding your data while using our products.</p>
            </div>
            <div className={style.textBlock}>
                <h2>Information Stored in Cardano Tokens Portfolio</h2>
                <p>The app employs AES-256 encryption for data storage, ensuring robust security. Access to this data requires the master password set during the app's initialization. This master password acts as the encryption key. Without it, your data remains unreadable. All your information is exclusively stored on your device.</p>
            </div>
            <div className={style.textBlock}>
                <h2>Information Collection</h2>
                <ul>
                    <li>Local Storage: All data input into the app remains on your device. We neither transfer nor utilize any of your stored information. Moreover, it is always encrypted and becomes indecipherable without your master password.</li>
                    <li>Personal Information: If you reach out to us via email or our application's contact form, we collect the email addresses and any other information you voluntarily provide.</li>
                    <li>Non-Personal Information: This includes, but isn't limited to, your device’s configuration, the application ID, and the version of the app you're using.</li>
                    <li>Diagnostic Reports: In the event of application crashes, diagnostic reports may be generated. Our technical team can access these reports, which contain purely technical data and are fully anonymized.</li>
                </ul>
                <p>We ensure that we only collect essential information for service provision and restrict internal access to your personal information, as detailed in the Information Usage section below.</p>
            </div>
            <div className={style.textBlock}>
                <h2>Information Usage</h2>
                <p>Your data remains confidential. It is exclusively used for internal purposes, including:</p>
                <ul>
                    <li>Responding to your emails, queries, comments, and feedback, and providing customer support.</li>
                    <li>Collating feedback for new features and addressing bug reports.</li>
                </ul>
                <p>Feel free to inquire about how your data is processed, its location, and its purpose at any time.</p>
            </div>
            <div className={style.textBlock}>
                <h2>Your Responsibilities for Protecting Your Data</h2>
                <p>Upon starting the app, you'll create a master password. It's crucial to choose a secure password to prevent unauthorized access. Please understand the significance of safeguarding your master password; anyone with access to it and your device can view your data. Always store a copy of your master password securely. We will never request it, and you should never disclose it to anyone.</p>
                <p>Some devices offer alternative access methods, such as fingerprint or face recognition. While these features enhance convenience, they may reduce your data's security level.</p>
            </div>
            <div className={style.textBlock}>
                <h2>Data Erasure</h2>
                <p>If you wish to delete your data from the app, simply uninstall the application from your device.</p>
            </div>
            <div className={style.textBlock}>
                <h2>Updates to our Privacy Policy</h2>
                <p>We reserve the right to modify this policy and will indicate the last revision date. Regularly reviewing this page will keep you informed about any changes to our Privacy Policy.</p>
            </div>
            <div className={style.textBlock}>
                <h2>Contact Us</h2>
                <p>For further inquiries, please email our support team directly at: info@intellisoftalpin.com</p>
            </div>
            <div className={style.textBlock}>
                <b>© 2023, Black Rocket Space Team</b>
                <b>Privacy Policy v.1.0, updated on October 20, 2023.</b>
            </div>
        </div>
    );
};

export default MobilePrivacyPolicy;
