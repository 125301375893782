import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import style from './Token.module.scss'
import { TOKEN_PAGE } from "../../../utils/const";
import { numberWithSpaces } from "../../../utils/numberWithSpaces";
import Trend from "../../Trend/Trend";
import blockchainLogo from '../../../assets/images/blockchainLogo.png'
import internetLogo from '../../../assets/images/internetLogo.png'
import { formatNumber } from "../../../utils/formatNumber";
import TokenMenu from "../TokenMenu/TokenMenu";
import notFoundTokenImage from "../../../assets/images/notFoundTokenImage.png";
import { useSelector } from "react-redux";

const Token = (props) => {
    const image = `https://ctokens.io/api/v1/tokens/images/${props.data.policy_id}.${props.data.asset_id}.png`

    const [isMenuToken, setMenuToken] = useState(false)

    const {courseUSD} = useSelector(state => state.tokens)

    const isMobile = useMediaQuery({query: `(max-width: 576px)`});

    const history = useNavigate()

    return (
        <div className={style.tokens} id={props.data.name}>
            <div className={style.token}
                 onClick={isMobile ? () => setMenuToken(!isMenuToken) : () => history(`${TOKEN_PAGE}/${props.data.policy_id}.${props.data.asset_name}`)}>
                <div className={style.numberToken}>
                    <span>{props.number}</span>
                </div>
                <div className={style.nameTokenBlock}>
                    <div className={style.imageToken}>
                        <img src={image} onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = notFoundTokenImage
                        }} alt=""/>
                    </div>
                    <div className={style.nameToken}>
                        <span>{props.data.name}</span>
                    </div>
                    <Trend trend={props.data.trend}/>
                </div>
                <div
                    className={isMobile && props.isSorter.sorter === 'byPrice' ? style.priceTokenMobile : style.priceToken}>
                    <span>₳ {formatNumber(props.data.price_ada.noExponents())}</span>
                    <span style={{color: 'orange'}}>$ {formatNumber(props.data.price_usd.noExponents())}</span>
                </div>
                <div
                    className={isMobile && props.isSorter.sorter === 'byLiquidity' ? style.priceTokenMobile : style.priceToken}>
                    <span>₳ {numberWithSpaces(props.data.locked_liquidity_ada.toFixed(0))}</span>
                    <span
                        style={{color: 'orange'}}>$ {numberWithSpaces((props.data.locked_liquidity_ada * courseUSD).toFixed(0))}</span>
                </div>
                <div
                    className={isMobile & props.isSorter.sorter === 'byLinks' || props.isSorter.sorter === 'byName' ? style.linksMobile : style.links}>
                    {
                        props.data.token_link &&
                        <div className={style.link}>
                            <a href={props.data.token_link} target='_blank' rel="noreferrer"
                               onClick={e => e.stopPropagation()}>
                                <img src={internetLogo} alt=""/>
                            </a>
                        </div>
                    }

                    <div className={style.link}>
                        <a href={`https://cardanoscan.io/token/${props.data.policy_id}.${props.data.asset_name}`}
                           target='_blank' rel="noreferrer"
                           onClick={e => e.stopPropagation()}>
                            <img src={blockchainLogo} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
            {isMobile && <TokenMenu
                isMenuToken={isMenuToken}
                liquidity={{
                    usd: numberWithSpaces((props.data.locked_liquidity_ada * props.courseUsd).toFixed(0)),
                    ada: numberWithSpaces(props.data.locked_liquidity_ada.toFixed(0))
                }}
                price={{
                    usd: formatNumber(props.data.price_usd.noExponents()),
                    ada: formatNumber(props.data.price_ada.noExponents())
                }}
                url={`${TOKEN_PAGE}/${props.data.policy_id}`}
            />}
        </div>
    );
};


export default Token;
