const GET_ALL_EXCHANGES = 'GET_ALL_EXCHANGES'
const GET_ONE_EXCHANGE = 'GET_ONE_EXCHANGE'


const initialState = {
    exchanges: [],
    exchange: {}

}

export default function exchangesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_EXCHANGES:
            return {
                ...state,
                exchanges: action.payload,
            }
        case GET_ONE_EXCHANGE:
            return {
                ...state,
                exchange: action.payload
            }
        default:
            return state
    }
}

export const getAllExchangesAction = (payload) => ({type: GET_ALL_EXCHANGES, payload})
export const getOneExchangeAction = (payload) => ({type: GET_ONE_EXCHANGE, payload})
