import React from 'react';
import {Routes, Route} from 'react-router-dom'
import {routes} from "../../utils/routes";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";

const AppRouter = () => {
    return (
        <Routes>
            {routes.map(({Element, path}) =>
                <Route element={Element} path={path} key={path}/>
            )}
            <Route element={<NotFoundPage />} path={'*'}/>
        </Routes>
    );
};

export default AppRouter;