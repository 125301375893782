import React from 'react';

const FaqPage = () => {
    return (
        <div>
            FAQ
        </div>
    );
};

export default FaqPage;