const GET_ALL_TOKENS = 'GET_ALL_TOKENS'
const GET_ONE_TOKEN = 'GET_ONE_TOKEN'
const SORT_TOKENS = 'SORT_TOKENS'

const initialState = {
    tokens: [],
    viewTokens: [],
    token: {},
    courseUSD: 0,
    time: '',
}

export default function tokensReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_TOKENS:
            return {
                ...state,
                tokens: action.payload.tokens,
                viewTokens: action.payload.tokens,
                courseUSD: action.payload.usd,
                time: action.payload.updated
            }
        case GET_ONE_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SORT_TOKENS:
            return {
                ...state,
                viewTokens: action.payload.tokens
            }
        default:
            return state
    }
}

export const getAllTokensAction = (payload) => ({type: GET_ALL_TOKENS, payload})
export const getOneTokenAction = (payload) => ({type: GET_ONE_TOKEN, payload})
export const sortTokens = (payload) => ({type: SORT_TOKENS, payload})