import React from 'react';
import {useNavigate} from 'react-router-dom'
import style from "./Exchange.module.scss";
import image from "../../../assets/images/logo.svg";
import {EXCHANGE_PAGE} from "../../../utils/const";
import blockchainLogo from '../../../assets/images/blockchainLogo.png'
import internetLogo from '../../../assets/images/internetLogo.png'
import appLink from '../../../assets/images/appLink.png'

const Exchange = ({data, number}) => {

    const history = useNavigate()

    return (
        <div className={style.exchangeBlock} onClick={() => history(`${EXCHANGE_PAGE}/${data.name}`)}>
            <div className={style.numberExchange}>
                <span>{number}</span>
            </div>
            <div className={style.nameExchange}>
                <img src={image} alt=""/>
                <span>{data.name}</span>
            </div>
            <div className={style.typeExchange}>
                <span>{data.ex_type}</span>
            </div>
            <div className={style.categoryExchange}>
                <span>{data.token}</span>
            </div>
            <div className={style.siteExchange}>
                <div className={style.link}>
                    <a href={data.app_url} target='_blank' rel="noreferrer" onClick={e => e.stopPropagation()}>
                        <img src={appLink} alt=""/>
                    </a>
                </div>
                <div className={style.link}>
                    <a href={data.token_link} target='_blank' rel="noreferrer" onClick={e => e.stopPropagation()}>
                        <img src={blockchainLogo} alt=""/>
                    </a>
                </div>
                <div className={style.link}>
                    <a href={data.url} target='_blank' rel="noreferrer" onClick={e => e.stopPropagation()}>
                        <img src={internetLogo} alt=""/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Exchange;