import React, {useEffect, useState} from 'react';
import style from './InformationExchangePage.module.scss'
import image from '../../assets/images/EncryptoPortfolio.svg'
import {useDispatch, useSelector} from "react-redux";
import TokensBlock from "../../components/TokensBlock/TokensBlock";
import {fetchExchange} from "../../actions/exchanges";
import {useParams} from "react-router-dom";
import appImage from '../../assets/images/appLink.png'
import tokenImage from '../../assets/images/internetLogo.png'
import blockchainImage from '../../assets/images/blockchainLogo.png'

const InformationExchangePage = () => {
    const deletedTokens = ['IIC', 'madWBTC', 'madWETH']


    const {exchangeId} = useParams()
    const tokens = useSelector(state => state.tokens)
    const exchange = useSelector(state => state.exchanges.exchange)
    const dispatch = useDispatch()
    const [isTokens, setTokens] = useState({viewTokens: []})

    const currentPage = `currentInformationExchangePage${exchange.name}`
    const selectedPage = `selectedInformationExchangePage${exchange.name}`


    if (window.sessionStorage.getItem(currentPage) === null){
        window.sessionStorage.setItem(currentPage, '1')
        window.sessionStorage.setItem(selectedPage, '1')
    }

    const [currentInformationExchangePage, setCurrentInformationExchangePage] = useState(Number(window.sessionStorage.getItem(currentPage)))
    const [selectedInformationExchangePage, setSelectedInformationExchangePage] = useState(Number(window.sessionStorage.getItem(selectedPage)))

    useEffect(() => {
        setCurrentInformationExchangePage(JSON.parse(window.sessionStorage.getItem(currentPage)))
        setSelectedInformationExchangePage(JSON.parse(window.sessionStorage.getItem(selectedPage)))
    }, [])

    useEffect(() => {
        window.sessionStorage.setItem(currentPage, `${currentInformationExchangePage}`)
        window.sessionStorage.setItem(selectedPage, `${selectedInformationExchangePage}`)
    }, [currentInformationExchangePage,
            selectedInformationExchangePage,
            setSelectedInformationExchangePage,
            setCurrentInformationExchangePage])

    useEffect(() => {
        if (tokens.tokens.length !== 0) {
            const tokensCurrentExchange = tokens.tokens.filter(data => data.exchanges.toString().includes(exchange.id))
            setTokens({viewTokens: tokensCurrentExchange.filter(item => !deletedTokens.includes(item.name))})
        }
    }, [exchange.id, tokens.tokens])

    const [isSorter, setSorter] = useState({sorter: '', isSorterArrow: ''})

    useEffect(() => {
        dispatch(fetchExchange(exchangeId))
    }, [dispatch, exchangeId])

    return (
        <div className={style.exchangeBlock}>
            <div className={style.exchangeDescriptionBlock}>
                <div className={style.nameDescriptionBlock}>
                    <div className={style.exchangeToken}>
                        <div>
                            <img src={image} alt=""/>
                        </div>
                    </div>
                    <div className={style.descriptionExchange}>
                        <p>{exchange.description}</p>
                    </div>
                </div>
                <div className={style.pricesExchangeBlock}>
                    <div className={style.headerPrices}>
                        <span>{exchange.name}</span>
                    </div>
                    <div className={style.pricesBlock}>
                        <div className={style.prices}>
                            <div className={style.priceBlock}>
                                <div className={style.headerPrice}>
                                    <span>TYPE</span>
                                </div>
                                <div className={style.price}>
                                    <span>{exchange.ex_type}</span>
                                </div>
                            </div>
                            <div className={style.priceBlock}>
                                <div className={style.headerPrice}>
                                    <span>STATUS</span>
                                </div>
                                <div className={style.price}>
                                    <span>{exchange.active && exchange.active ? 'Active' : 'Unactive'}</span>
                                </div>
                            </div>
                            <div className={style.priceBlock}>
                                <div className={style.headerPrice}>
                                    <span>TOKEN</span>
                                </div>
                                <div className={style.price}>
                                    <span>{exchange.token}</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.links}>
                            <div className={style.linkBlock}>
                                <div className={style.linkImage}>
                                    <img src={appImage} alt=""/>
                                </div>
                                <div className={style.linkText}>
                                    <a href={exchange.app_url} target="_blank" rel="noreferrer">
                                        App
                                    </a>
                                </div>
                            </div>
                            <div className={style.linkBlock}>
                                <div className={style.linkImage}>
                                    <img src={blockchainImage} alt=""/>
                                </div>
                                <div className={style.linkText}>
                                    <a href={exchange.token_link} target="_blank"
                                       rel="noreferrer">Blockchain</a>
                                </div>
                            </div>
                            <div className={style.linkBlock}>
                                <div className={style.linkImage}>
                                    <img src={tokenImage} alt=""/>
                                </div>
                                <div className={style.linkText}>
                                    <a href={exchange.url} target="_blank"
                                       rel="noreferrer">Token</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.exchangeDescriptionBlockMobile}>
                <div className={style.exchangeNameMobile}>
                    <img src={image} alt=""/>
                    <span>{exchange.name}</span>
                </div>
                <div className={style.exchangePricesMobile}>
                    <div className={style.exchangePriceMobile}>
                        <div className={style.namePriceMobile}>
                            <span>TYPE</span>
                        </div>
                        <div className={style.priceMobile}>
                            <span>{exchange.ex_type}</span>
                        </div>
                    </div>
                    <div className={style.exchangePriceMobile}>
                        <div className={style.namePriceMobile}>
                            <span>STATUS</span>
                        </div>
                        <div className={style.priceMobile}>
                            <span>{exchange.active && exchange.active ? 'Active' : 'Unactive'}</span>
                        </div>
                    </div>
                    <div className={style.exchangePriceMobile}>
                        <div className={style.namePriceMobile}>
                            <span>TOKEN</span>
                        </div>
                        <div className={style.priceMobile}>
                            <span>{exchange.token}</span>
                        </div>
                    </div>
                </div>
                <div className={style.tokenLinksMobile}>
                    <div className={style.mobileLink}>
                        <img src={appImage} alt=""/>
                        <a href={exchange.app_url} target="_blank" rel="noreferrer">App</a>
                    </div>
                    <div className={style.mobileLink}>
                        <img src={blockchainImage} alt=""/>
                        <a href={exchange.token_link} target="_blank" rel="noreferrer">Blockchain</a>
                    </div>
                    <div className={style.mobileLink}>
                        <img src={tokenImage} alt=""/>
                        <a href={exchange.url} target="_blank" rel="noreferrer">Token</a>
                    </div>
                </div>
            </div>

            <div className={style.tokensBlock}>
                <TokensBlock isSorter={isSorter}
                             setSorter={setSorter}
                             tokens={isTokens}
                             selected={selectedInformationExchangePage}
                             setSelected={setSelectedInformationExchangePage}
                             currentPage={currentInformationExchangePage}
                             setCurrentPage={setCurrentInformationExchangePage}/>
            </div>
        </div>
    );
};

export default InformationExchangePage;