import React, {useEffect} from 'react'
import style from './App.module.scss'
import AppRouter from "./components/AppRouter/AppRouter";
import NavigationPanel from "./components/NavagationPanel/NavigationPanel";
import PortfolioInformation from "./components/PortfolioInformation/PortfolioInformation";
import {useDispatch} from "react-redux";
import {fetchTokens} from "./actions/tokens";
import {fetchExchanges} from "./actions/exchanges";

const App = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchTokens())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchExchanges())
    }, [dispatch])

    return (
        <div className={style.app}>
            <NavigationPanel/>
            <AppRouter/>
            <PortfolioInformation/>
        </div>
    );
};

export default App;
