import React from 'react';
import style from './PortfolioInformation.module.scss'
import imagePortfolio from '../../assets/images/EncryptoPortfolio.svg'
import { Link } from "react-router-dom";
import { MOBILE_PRIVACY_POLICY_PAGE } from "../../utils/const";

const PortfolioInformation = () => {

    const openEncrypto = () => {
        window.open('https://encryptoportfolio.com/','_blank')
    }

    return (
        <div className={style.portfolioBlock}>
            <div className={style.imagePortfolio}>
                <a href="https://encryptoportfolio.com/" target="_blank" rel='noreferrer'>
                    <img src={imagePortfolio} alt=""/>
                </a>

            </div>
            <div className={style.textPortfolio}>
                <div className={style.textEncryptoInformation}>
                    <span>
                        EnCrypto Portfolio Offline - The safest Portfolio Manager for Cardano Tokens (and not only)
                    </span>
                    <Link to={MOBILE_PRIVACY_POLICY_PAGE} className={style.link}>
                        Mobile App Privacy Policy
                    </Link>
                </div>
                <div className={style.footerInformation}>
                    <span>©2023 Cardano Tokens & Exchanges</span>
                </div>
            </div>
            <div className={style.btnPortfolio}>
                <button onClick={openEncrypto}>EnCrypto Portfolio</button>
            </div>
        </div>
    );
};

export default PortfolioInformation;
