import React from 'react';
import { useNavigate } from 'react-router-dom'
import style from './TokenMenu.module.scss'

const TokenMenu = ({isMenuToken, liquidity, price, url}) => {

    const history = useNavigate()

    return (
        <>
            {isMenuToken &&
                <div className={style.tokenMenuBlock} onClick={() => history(url)}>
                    <div className={style.tokenMenuItem}>
                        <span>Price</span>
                        <span>{price.ada} ₳ / <span style={{color: 'orange'}}>{price.usd} $</span></span>
                    </div>
                    <div className={style.tokenMenuItem}>
                        <span>Liquidity</span>
                        <span>{liquidity.ada} ₳ / <span style={{color: 'orange'}}>{liquidity.usd} $</span></span>
                    </div>
                    <div className={style.tokenMenuItem}>
                        <button className={style.tokenMenuButton}>More information</button>
                    </div>

                </div>
            }
        </>

    );
};

export default TokenMenu;
