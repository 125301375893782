import React from "react";
import {
    ABOUT_PAGE,
    EXCHANGE_PAGE,
    EXCHANGES_PAGE,
    FAQ_PAGE,
    TOKEN_PAGE,
    TOKENS_PAGE,
    PORTFOLIO_PAGE,
    MOBILE_PRIVACY_POLICY_PAGE
} from "./const";
import ExchangesPage from "../pages/ExchangesPage/ExchangesPage";
import TokensPage from '../pages/TokensPage/TokensPage';
import FaqPage from "../pages/FaqPage/FaqPage";
import AboutPage from "../pages/AboutPage/AboutPage";
import InformationTokenPage from "../pages/InformationTokenPage/InformationTokenPage";
import InformationExchangePage from "../pages/InformationExchangePage/InformationExchangePage";
import PortfolioPage from "../pages/PortfolioPage/PortfolioPage";
import MobilePrivacyPolicy from "../pages/MobilePrivacyPolicy/MobilePrivacyPolicy";


export const routes = [
    {
        Element: <TokensPage/>,
        path: TOKENS_PAGE
    },
    {
        Element: <InformationTokenPage/>,
        path: TOKEN_PAGE + '/:tokenId'
    },
    {
        Element: <ExchangesPage/>,
        path: EXCHANGES_PAGE
    },
    {
        Element: <InformationExchangePage/>,
        path: EXCHANGE_PAGE + '/:exchangeId'
    },
    {
        Element: <FaqPage/>,
        path: FAQ_PAGE
    },
    {
        Element: <AboutPage/>,
        path: ABOUT_PAGE
    },
    {
        Element: <PortfolioPage/>,
        path: PORTFOLIO_PAGE
    },
    {
        Element: <MobilePrivacyPolicy/>,
        path: MOBILE_PRIVACY_POLICY_PAGE
    }
]
