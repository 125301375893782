import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import style from './NavigationPanel.module.scss'
import logo from '../../assets/images/logo.svg'
import { ABOUT_PAGE, EXCHANGES_PAGE, PORTFOLIO_PAGE, TOKENS_PAGE } from "../../utils/const";
import MobileNavigationModal from "../MobileNavigationModal/MobileNavigationModal";
import navArrow from '../../assets/images/arrowDown.svg'
import { useSelector } from "react-redux";


const NavigationPanel = () => {
    const history = useNavigate()
    const setActive = ({isActive}) => isActive ? style.linkActive : style.link
    const [isModal, setModal] = useState(false)
    const onCloseModal = () => setModal(false)
    const onOpenModal = () => setModal(true)

    const location = useLocation()
    const [isMobileTextLink, setMobileTextLink] = useState('Tokens')

    useEffect(() => {
        if (location.pathname === '/') setMobileTextLink('Tokens')
        else setMobileTextLink(location.pathname.split('/')[1].substring(0, 1).toUpperCase() + location.pathname.split('/')[1].substring(1))

    }, [location.pathname, isMobileTextLink])

    const tokens = useSelector(state => state.tokens)

    const formatTime = (date) => {
        const nowDate = new Date()
        if (date) {
            const {day, month, year, hours, minutes, seconds} = getAllDataAboutDate(date),
                timezoneUtcDate = new Date(year, month - 1, day, hours, minutes, seconds)
            const returnDate = new Date(timezoneUtcDate.getTime() - (nowDate.getTimezoneOffset() * 60 * 1000))
            return returnDate.toLocaleString()
        }
    }

    const getAllDataAboutDate = (date) => {
        if (date) {
            const day = +date.split('T')[0].split('-')[2],
                month = +date.split('T')[0].split('-')[1],
                year = +date.split('T')[0].split('-')[0],
                hours = +date.split('T')[1].split(':')[0],
                minutes = +date.split('T')[1].split(':')[1],
                seconds = +date.split('T')[1].split(':')[2].slice(0, -1)

            return {day, month, year, hours, minutes, seconds}
        }
    }

    return (
        <>
            <MobileNavigationModal visible={isModal} onClose={onCloseModal}/>
            <nav className={style.navigationPanel}>
                <div className={style.navigationLogoBlock} onClick={() => history(TOKENS_PAGE)}>
                    <img src={logo} alt="logo"/>
                    <div className={style.appName}>
                        <span>Cardano Tokens</span>

                    </div>
                </div>
                <div className={style.navigationLinks}>
                    <div className={style.navigationLink}>
                        <NavLink to={TOKENS_PAGE} className={setActive}>Tokens</NavLink>
                    </div>
                    <div className={style.navigationLink}>
                        <NavLink to={EXCHANGES_PAGE} className={setActive}>Exchanges</NavLink>
                    </div>
                    <div className={style.navigationLink}>
                        <NavLink to={PORTFOLIO_PAGE} className={setActive}>Portfolio</NavLink>
                    </div>
                    <div className={style.navigationLink}>
                        <NavLink to={ABOUT_PAGE} className={setActive}>About</NavLink>
                    </div>
                </div>
                <div className={style.mobileAdaUsdText}>
                    <div><span>ADA/USD </span></div>
                </div>
                <div className={style.navigationCourseAda}>
                    <div className={style.adaUsd}><span style={{color: '#FFBD00'}}>ADA/USD </span> - 1 ₳ / <span
                        style={{color: '#FFBD00'}}>{tokens.courseUSD.toFixed(2)} $</span></div>
                    <div className={style.time}>Time: {formatTime(tokens.time)}</div>
                    <div className={style.mobileBlock}>
                        <div className={style.mobilePriceAndTime}>
                            <span> 1 ₳ / <span style={{color: '#FFBD00'}}>{tokens.courseUSD.toFixed(2)}$</span></span>
                            <span>{formatTime(tokens.time)}</span>
                        </div>
                        <div className={style.mobileActiveLink} onClick={onOpenModal}>
                            <span>{isMobileTextLink}</span>
                            <img src={navArrow} alt=""/>
                        </div>
                    </div>
                </div>
            </nav>
        </>

    );
};

export default NavigationPanel;