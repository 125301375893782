import React from 'react';
import style from './ExchangeHeader.module.scss'

const ExchangeHeader = () => {
    return (
        <div className={style.headerExchange}>
            <div className={style.numberExchangeHeader}>
                <span>NO</span>
            </div>
            <div className={style.nameExchangeHeader}>
                <span>NAME</span>
            </div>
            <div className={style.typeExchangeHeader}>
                <span>TYPE</span>
            </div>
            <div className={style.categoryExchangeHeader}>
                <span>TOKEN</span>
            </div>
            <div className={style.siteExchangeHeader}>
                <span>LINKS</span>
            </div>
        </div>
    );
};

export default ExchangeHeader;