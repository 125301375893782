import React from 'react';
import style from './TokensHeaderMobile.module.scss'
import arrowDown from '../../../../assets/images/arrowDownForSort.png'
import arrowUp from '../../../../assets/images/arrowUpForSort.png'

const TokensHeaderMobile = ({sort, isSorter, setSorter}) => {
    return (
        <div className={style.tokensHeaderMobile}>
            <div className={style.tokenPropertyNumber}>
                <span>NO</span>
            </div>
            <div className={`${style.tokenPropertyName} ${isSorter.sorter === 'byName' && style.activeElement}`} onClick={() => sort('byName')}>
                <span>COIN</span>
                {isSorter.sorter === 'byName' &&
                    <>
                        {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown'/>}
                        {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp'/>}
                    </>
                }
            </div>
            <div className={`${style.tokenPropertyPriceAdaAndUsd} ${isSorter.sorter === 'byPrice' && style.activeElement}`} onClick={() => sort('byPrice')}>
                <span>PRICE ADA & USD</span>
                {isSorter.sorter === 'byPrice' &&
                    <>
                        {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown'/>}
                        {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp'/>}
                    </>
                }
            </div>
            <div className={`${style.tokenPropertyMarketCap} ${isSorter.sorter === 'byLiquidity' && style.activeElement}`} onClick={() => sort('byLiquidity')}>
                <span>LIQUIDITY</span>
                {isSorter.sorter === 'byLiquidity' &&
                    <>
                        {isSorter.isSorterArrow === 'arrowDown' && <img src={arrowDown} alt='arrowDown'/>}
                        {isSorter.isSorterArrow === 'arrowUp' && <img src={arrowUp} alt='arrowUp'/>}
                    </>
                }
            </div>
            <div className={`${style.tokenPropertyLinks} ${isSorter.sorter === 'byLinks' && style.activeElement}`} onClick={() => setSorter({sorter: 'byLinks'})}>
                <span>LINKS</span>
            </div>
        </div>
    );
};

export default TokensHeaderMobile;
