export function formatNumber(stringNumber) {
    stringNumber += ''
    const returnPrice = +stringNumber
    if (stringNumber >= 1 && stringNumber.split('.')[1] === undefined) return returnPrice
    if (stringNumber >= 1) {
        return returnPrice.toFixed(2)
    } else {
        const curPriceFractionPart = stringNumber.split('.')[1]
        if (curPriceFractionPart === undefined) return returnPrice
        for (let i = 0; i < curPriceFractionPart.length; i++) {
            if (+curPriceFractionPart[i] !== 0) {
                return returnPrice.toFixed(i + 2)
            }
        }
    }
}