import React from 'react';
import style from './ModalSelectWallet.module.scss'
import nami from '../../../assets/images/wallets/nami.svg'
import typhon from '../../../assets/images/wallets/typhon.svg'
import flint from '../../../assets/images/wallets/flint.svg'
import gero from '../../../assets/images/wallets/gero.svg'
import eternl from '../../../assets/images/wallets/eternl.svg'
import close from '../../../assets/images/closeModal.svg'

const ModalSelectWallet = ({visible, onClose, setActiveWallet}) => {
    const wallets = [
        {
            name: 'Nami',
            src: nami
        },
        {
            name: 'Typhon',
            src: typhon
        },
        {
            name: 'Flint',
            src: flint
        },
        {
            name: 'GeroWallet',
            src: gero
        },
        {
            name: 'Eternl',
            src: eternl
        }
    ]

    const selectWalletToConnect = async (name) => {
        if (name === 'Nami') {
            try {
                await window.cardano.nami.enable().then(() => {
                    localStorage.setItem('name', window.cardano.nami.name)
                    localStorage.setItem('icon', window.cardano.nami.icon)
                    onClose()
                    setActiveWallet()
                }).catch(console.log)
            } catch (e) {
                window.open('https://namiwallet.io/', '_blank')
            }

        } else if (name === 'Typhon') {
            try {
                await window.cardano.typhoncip30.enable().then(() => {
                    localStorage.setItem('name', window.cardano.typhon.name)
                    localStorage.setItem('icon', window.cardano.typhon.icon)
                    setActiveWallet()
                    onClose()
                }).catch(console.log)
            } catch (e) {
                window.open('https://typhonwallet.io/#/', '_blank')
            }

        } else if (name === 'Flint') {
            try {
                await window.cardano.flint.enable().then(() => {
                    localStorage.setItem('name', window.cardano.flint.name)
                    localStorage.setItem('icon', window.cardano.flint.icon)
                    setActiveWallet()
                    onClose()
                }).catch(console.log)
            } catch (e) {
                window.open('https://flint-wallet.com/', '_blank')
            }
        } else if (name === 'GeroWallet') {
            try {
                await window.cardano.gerowallet.enable().then(() => {
                    localStorage.setItem('name', window.cardano.gerowallet.name)
                    localStorage.setItem('icon', window.cardano.gerowallet.icon)
                    setActiveWallet()
                    onClose()
                }).catch(console.log)
            } catch (e) {
                window.open('https://gerowallet.io/', '_blank')
            }
        } else if (name === 'Eternl') {
            try {
                await window.cardano.eternl.enable().then(() => {
                    localStorage.setItem('name', window.cardano.eternl.name)
                    localStorage.setItem('icon', window.cardano.eternl.icon)
                    setActiveWallet()
                    onClose()
                }).catch(console.log)
            } catch (e) {
                window.open('https://eternl.io/app/mainnet/welcome', '_blank')
            }
        }
    }


    if (!visible) return null
    return (
        <div className={style.modalSelectWallet} onClick={onClose}>
            <div className={style.container} onClick={e => e.stopPropagation()}>
                <div className={style.informationConnect}>
                    <div>Connect wallet</div>
                    <img src={close} alt="" onClick={onClose}/>
                </div>
                <div className={style.listWallets}>
                    {wallets.map((item) =>
                        <div className={style.wallet} onClick={() => selectWalletToConnect(item.name)} key={item.name}>
                            <span>{item.name}</span>
                            <img src={item.src} alt=""/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalSelectWallet;